import { Container, Footer, Header, Loader, Text } from "app/components";
import { ModuleChaptersList, ModuleHeader } from "./components";
import { useEffect, useState, useMemo } from "react";
import { moduleService } from "../../services";
import { useParams } from "react-router-dom";

export default function ModulesScreen() {
  const [data, setData] = useState();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const module = await moduleService.getOne(params.id);
    setData({ module });
    setLoading(false);
  }

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else {
      return data?.module ? (
        <>
          <ModuleHeader title={data.module.title} />
          <Container>
            <ModuleChaptersList chapters={data.module.chapters} />
          </Container>
        </>
      ) : (
        <Text>Aucun module trouvé</Text>
      );
    }
  }, [loading, data]);

  return (
    <>
      <Header />
      {content}
      <Footer />
    </>
  );
}
