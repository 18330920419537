import profile from "app/assets/img/icons/me.svg";
import { Text } from "app/components";
import style from "./ProfileMenu.module.scss";
import { useAuth } from "app/context";
import { Link } from "react-router-dom";

export default function ProfileMenu() {
  const { user } = useAuth();

  return (
    <Link to="/me" className={style.link}>
      <div className={style.profileMenu}>
        <img src={profile} alt={user.firstname || user.email} width={40} height={40} />
        <Text type="bold">{user.firstname || user.email}</Text>
      </div>
    </Link>
  );
}
