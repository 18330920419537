import { ModuleCourse } from "./components";
import style from "./ModuleContent.module.scss";

export default function ModuleContent({ courses }) {
  const courseList = courses.map((course, index) => {
    const position = index === 0 ? "first" : index === courses.length - 1 ? "last" : "";

    return <ModuleCourse key={index} course={course} position={position} />;
  });

  return <div className={style.container}>{courseList}</div>;
}
