import { Api, Constants } from "app/utils";
import axios from "axios";

class UserService {
  getStoredUser() {
    let user;
    try {
      user = JSON.parse(localStorage.getItem(Constants.variables.WOON_USER));
    } catch (error) {
      console.error("Can't retrieve user from localstorage", error);
    }
    if (user === null) {
      return {};
    }
    return user;
  }

  async login(email, password) {
    try {
      let response = await axios.post(
        Constants.routes.API_URL + Constants.routes.SIGNIN_URL,
        { email, password },
        { withCredentials: true }
      );
      if (response?.data) {
        localStorage.setItem(Constants.variables.WOON_USER, JSON.stringify(response.data));
        return response.data;
      }
      return undefined;
    } catch (error) {
      console.error("Can't login user", error.response.data);
      throw error.response.data;
    }
  }

  async getOne(id) {
    try {
      let response = await Api.get(Constants.routes.USERS + "/" + id);
      return response.data;
    } catch (error) {
      console.error("Can't get user with id " + id);
      throw error.response.data;
    }
  }

  async getMe() {
    try {
      let response = await Api.get(Constants.routes.USER_ME);
    } catch (error) {}
  }

  async getAll() {
    try {
      const response = await Api.get(Constants.routes.USERS);
      return response.data || [];
    } catch (error) {
      console.error("Can't get users", error.response.data);
      throw error.response.data;
    }
  }

  async insert(data) {
    try {
      let response = await Api.post(Constants.routes.USERS, { user: data });
      return response.data;
    } catch (error) {
      console.error("Can't add user", error.response.data);
      throw error.response.data;
    }
  }

  async update(data) {
    try {
      let response = await Api.put(Constants.routes.USERS + "/" + data.id, { user: data });
      return response?.data;
    } catch (error) {
      console.error("Can't update user", error.response.data);
      throw error.response.data;
    }
  }

  async updateMe(data) {
    try {
      let response = await Api.put(Constants.routes.USERS + "/" + data.id, { user: data });
      if (response?.data) {
        localStorage.setItem(Constants.variables.WOON_USER, JSON.stringify(response.data));
        return response.data;
      }
      return response?.data;
    } catch (error) {
      console.error("Can't update user", error.response.data);
      throw error.response.data;
    }
  }
  async logout() {
    try {
      let response = await Api.get(Constants.routes.LOGOUT_URL);
      return response?.data;
    } catch (error) {
      console.error("Can't update user", error.response.data);
      throw error.response.data;
    }
  }
  async forgottenPassword(email) {
    try {
      await axios.post(
        Constants.routes.API_URL + Constants.routes.FORGOTTEN_PASSWORD_URL,
        { email },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Can't send forgotten password request", error.response.data);
      throw error.response.data;
    }
  }
  async resetPassword({ pass1, pass2, token }) {
    try {
      await axios.post(
        Constants.routes.API_URL + Constants.routes.RESET_PASSWORD_URL,
        { pass1, pass2, token },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Can't send reset password request", error.response.data);
      throw error.response.data;
    }
  }
}

export default new UserService();
