import { Api, Constants } from "app/utils";

class MemberService {
  async getOne(id) {
    try {
      const response = await Api.get(`${Constants.routes.PROFESSIONALS}/${id}`);
      return response.data || [];
    } catch (error) {
      console.error("Can't get member " + id, error.response.data);
      throw error.response.data;
    }
  }
}

export default new MemberService();
