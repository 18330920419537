import { Button, Text } from "app/components";
import style from "./VideoCourse.module.scss";
import { VideoContent } from "./components";

export default function VideoCourse({ course, onClick }) {
  const buttonTitle = "J'ai visionné la vidéo";

  return (
    <div className={style.container}>
      <div className={style.sidebar}>
        <div className={style.title}>
          <Text type="h1">{course.title}</Text>
        </div>
        <div className={style.desc}>
          <div dangerouslySetInnerHTML={{ __html: course.long_description }} />
        </div>
        {!course.finished && (
          <div className={style.finish}>
            <Button onClick={onClick}>{buttonTitle}</Button>
          </div>
        )}
      </div>
      <div className={style.video}>
        <VideoContent content={course.content} />
      </div>
    </div>
  );
}
