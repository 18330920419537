import style from "./LoginScreen.module.scss";
import { Header } from "app/components";
import { LoginForm } from "app/forms";

export default function LoginScreen({ isLoggedIn }) {
  return (
    <>
      <Header />
      <div className={style.container}>
        <div className={style.form}>
          <LoginForm />
        </div>
      </div>
    </>
  );
}
