import style from "./Step.module.scss";
import { Text } from "app/components";

export default function Step({ step, handleClick }) {
  return (
    <div className={style.step} onClick={handleClick}>
      {/* <div
          onTouchStart={(touchStartEvent) => console.log(touchStartEvent)}
          onTouchMove={(touchMoveEvent) => console.log(touchMoveEvent)}
          onTouchEnd={() => console.log("onTouchEnd")}
          onMouseDown={(mouseDownEvent) => console.log(mouseDownEvent)}
          onMouseMove={(mouseMoveEvent) => console.log(mouseMoveEvent)}
          onMouseUp={() => console.log("onMouseUp")}
          onMouseLeave={() => console.log("onMouseLeave")}
        >
          swipe
        </div> */}
      <img src={step.image} alt={step.title} width="303" height="200" />
      <div className={style.title}>
        <Text type="h1" color="blue">
          {step.title}
        </Text>
      </div>
      <div className={style.content}>
        <div dangerouslySetInnerHTML={{ __html: step.html }} />
      </div>
    </div>
  );
}
