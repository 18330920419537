import { AccordionItem } from "./components";
import { useState } from "react";
import style from "./Accordion.module.scss";

export default function Accordion({ items, forceActive = false }) {
  const [activeItem, setActiveItem] = useState(null);

  const renderedItems = items.map((item, index) => {
    const isActive = activeItem === index || forceActive;
    const showContent = isActive ? "show-content" : "none";
    const ariaExpanded = isActive ? "true" : "false";
    return (
      <AccordionItem
        key={index}
        showContent={showContent}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => {
          setActiveItem(index);
        }}
      />
    );
  });

  return <div className={style.container}>{renderedItems}</div>;
}
