import style from "./AccordionItem.module.scss";

export default function AccordionItem({ showContent, ariaExpanded, item, index, onClick }) {
  return (
    <div className={style.container}>
      <div
        className={style.title}
        aria-expanded={ariaExpanded}
        aria-controls={`item${index + 1}_content`}
        onClick={onClick}
      >
        {item.title}
      </div>
      <div className={`${style.content} ${style[showContent]}`} id={`item${index + 1}_content`}>
        {item.content}
      </div>
    </div>
  );
}
