import style from "./Text.module.scss";

export default function Text({ type = "normal", color, children, linkTo, locale, targetBlank, title }) {
  const Element = () => <span className={`${style.text} ${style[type]} ${color && style[color]}`}>{children}</span>;

  function wrap() {
    if (linkTo) {
      return (
        <a
          href={linkTo}
          {...(locale ? { locale: locale } : "")}
          {...(targetBlank ? { target: "_blank" } : "")}
          {...(title ? { title: title } : "")}
          className={style.link}
        >
          <Element />
        </a>
      );
    }
    return <Element />;
  }

  return wrap();
}
