import jwt_decode from "jwt-decode";
import Constants from "./Constants";

const isTokenExpired = (token) => {
  try {
    if (token) {
      let decoded = jwt_decode(token);
      let expirationDate = new Date(decoded.exp * 1000);
      if (new Date() < expirationDate) {
        return false;
      }
    }
  } catch (error) {
    console.error("Can't decode token", error);
  }
  return true;
};

export const userHasRole = (roles) => {
  // At least one role is ok
  let storedUser = JSON.parse(localStorage.getItem(Constants.variables.WOON_USER));

  for (let i = 0; i < roles.length; i++) {
    if (storedUser.roles_id === roles[i]) {
      return true;
    }
  }
  return false;
};

const tokenUtils = {
  isTokenExpired,
  userHasRole,
};

export default tokenUtils;
