import { useAuth } from "app/context";
import { Navigate } from "react-router-dom";
import { userHasRole } from "app/utils/TokenUtil";

const ProtectedRoute = ({ children, roles }) => {
  const { user } = useAuth();

  if (!user?.id && window.location.pathname !== "/login") {
    return <Navigate to="/login" replace />;
  }

  if (roles && !userHasRole(roles)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
