import style from "./ModuleList.module.scss";
import { ModuleItem } from "./components";

export default function ModuleList({ modules }) {
  return (
    <div className={style.moduleList}>
      {modules && modules.map((module) => <ModuleItem key={module.id} module={module} />)}
    </div>
  );
}
