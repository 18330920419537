import { Header, Container, Text, Footer, TeamMembersList, Loader, BackButton } from "app/components";
import style from "./TeamScreen.module.scss";
import { useParams } from "react-router-dom";
import { specialityService } from "../../services";
import { useEffect, useState, useMemo } from "react";

export default function TeamScreen() {
  const [data, setData] = useState();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const team = await specialityService.getOne(params.id);
    setData({ team });
    setLoading(false);
  }

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else {
      return data?.team ? (
        <>
          <Container>
            <div className={style.headerContainer}>
              <BackButton to={"/"} />
              <Text type="h3" color="orange">
                Notre équipe
              </Text>
            </div>
            <Text type="h1">{data.team.name}</Text>
            <TeamMembersList members={data.team.professionals} />
          </Container>
        </>
      ) : (
        <Text>Aucun membre trouvé</Text>
      );
    }
  }, [loading, data]);

  return (
    <>
      <Header />
      <div className={style.container}>{content}</div>
      <Footer />
    </>
  );
}
