import style from "./ForgottenPasswordScreen.module.scss";
import { Header } from "app/components";
import { ForgottenPasswordForm, ResetPasswordForm } from "app/forms";
import { useParams } from "react-router-dom";

export default function ForgottenPasswordScreen() {
  let { token } = useParams();

  function renderForm() {
    if (token) {
      return <ResetPasswordForm token={token} />;
    }
    return <ForgottenPasswordForm />;
  }

  return (
    <>
      <Header />
      <div className={style.container}>
        <div className={style.form}>{renderForm()}</div>
      </div>
    </>
  );
}
