import style from "./Header.module.scss";
import Logo from "app/assets/img/logo_woon.svg";
import burger from "app/assets/img/icons/menu-burger.svg";
import { Menu, ProfileMenu } from "./components";
import { Container } from "app/components";
import { useAuth } from "app/context";

export default function Header() {
  const { user } = useAuth();

  const handleOnClick = () => {
    const menu = document.querySelector(`.${style.menuContainer}`);
    menu.classList.toggle(style.menuActive);
  };

  return (
    <header className={style.header}>
      <Container>
        <a href="/">
          <img src={Logo} alt="Woon logo" width={142} height={49} />
        </a>
        <div className={style.mainMenuContainer}>{user.id && <Menu />}</div>
        <div className={style.menuContainer}>
          {user.id ? <ProfileMenu /> : <div className={style.title}>Connexion</div>}
          <div className={style.mobileMenu}>{user.id && <Menu />}</div>
        </div>
        <div className={style.burgerContainer}>
          <img onClick={handleOnClick} className={style.burger} src={burger} alt="Menu burger" width={30} height={30} />
        </div>
      </Container>
    </header>
  );
}
