import style from "./ModuleItem.module.scss";
import { ProgressCircle } from "./components";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { Constants } from "app/utils";

export default function ModuleItem({ module }) {
  const cover = useMemo(() => {
    if (module?.image_couverture?.url) {
      return Constants.routes.API_URL + module.image_couverture.url;
    }
    return Constants.variables.DEFAULT_MODULE_AVATAR;
  }, [module]);

  return (
    <Link to={`/module/${module.id}`} className={style.module}>
      <img className={style.moduleImage} src={cover} alt={module.title} width={288} height={291} />
      <div className={style.moduleTitle}>{module.title}</div>
      <div className={style.progress}>
        {/* TODO : add advancement */}
        {/*  <ProgressCircle pourcent={module.advancement || 0} />*/}
      </div>
    </Link>
  );
}
