import { Header, Container, Footer, ToggleButton, Button } from "app/components";
import { Pagination } from "./components";
import style from "./AdminScreen.module.scss";
import { useState } from "react";
import { Api, Constants } from "app/utils";
import { Link } from "react-router-dom";
import { parseDate } from "app/utils/Utils";

export default function AdminScreen() {
  const [users, setUsers] = useState([]);

  function toggleUserActive(checked, user) {
    let usr = { ...user, active: checked };
    Api.put(Constants.routes.USERS_ACTIVE_URL, { user: usr })
      .then((res) => {
        setUsers((users) =>
          users.map((user) => {
            if (user.id === res.data.id) {
              return usr;
            } else {
              return user;
            }
          })
        );
      })
      .catch((e) => {
        console.error(e.response.data.message, "Oups !");
        // if (e.response && e.response.data.message) {
        //   NotificationManager.error(e.response.data.message, "Oups !");
        // }
      });
  }

  return (
    <>
      <Header />
      <div className={style.container}>
        <Container>
          <div>
            <Link to="/admin/users/add">
              <Button tiny>+ Ajouter</Button>
            </Link>
          </div>
          <Pagination
            url={Constants.routes.USERS_PAGINATED}
            onData={setUsers}
            init={{
              sortBy: "id",
              sortOrder: "desc",
            }}
            head={{
              active: "Actif",
              email: "Email",
              roles_id: "Role",
              firstname: "Prénom",
              lastname: "Nom",
              date_creation: "Date de création",
            }}
          >
            {users.map((user, index) => {
              return (
                <tr key={"user" + index}>
                  <td>
                    <div className={style.toggleButtonContainer}>
                      <ToggleButton checked={user.active} onChange={(checked) => toggleUserActive(checked, user)} />
                    </div>
                  </td>
                  <td>
                    <Link to={"/admin/users/" + user.id}>{user.email}</Link>
                  </td>
                  <td>{user.roles.label}</td>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{parseDate(user.date_creation)}</td>
                </tr>
              );
            })}
          </Pagination>
        </Container>
      </div>
      <Footer />
    </>
  );
}
