import "app/assets/style/_style.scss";
import { ProvideAuth } from "./app/context";
import Router from "app/routes/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ProvideAuth>
      <Router />
      <ToastContainer />
    </ProvideAuth>
  );
}

export default App;
