import style from "./BackButton.module.scss";
import { useNavigate } from "react-router-dom";

export default function BackButton({ to = -1 }) {
  const navigate = useNavigate();

  function handleOnClick() {
    navigate(to);
  }

  return (
    <div onClick={handleOnClick} className={style.backButton}>
      <img src="/back.svg" height="60" width="60" alt="Back" />
    </div>
  );
}
