import { Container } from "app/components";
import style from "./Footer.module.scss";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className={style.footer}>
      <Container>
        © {year} Woon |{" "}
        <a target="_blank" href="https://woon.care/mentions-legales/">
          Mentions légales
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://woon.care/politique-de-cookies-ue/">
          Politique de confidentialité
        </a>
      </Container>
    </footer>
  );
}
