import axios from "axios";
import { Constants } from "app/utils";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: Constants.routes.API_URL,
      withCredentials: true,
    });

    this.api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 498) {
          localStorage.clear();
          window.location = "/login";
        }
        throw error;
      }
    );
  }

  get(...args) {
    return this.call("get", ...args);
  }
  post(...args) {
    return this.call("post", ...args);
  }
  put(...args) {
    return this.call("put", ...args);
  }
  delete(...args) {
    return this.call("delete", ...args);
  }

  call(type, ...args) {
    return this.api[type](...args);
  }
}

export default new Api();
