import { Api, Constants } from "app/utils";

class ModuleService {
  async getAll() {
    try {
      const response = await Api.get(Constants.routes.MODULES);
      return response.data || [];
    } catch (error) {
      console.error("Can't get modules", error.response.data);
      throw error.response.data;
    }
  }

  async getOne(id) {
    try {
      const response = await Api.get(`${Constants.routes.MODULES}/${id}`);
      return response.data || [];
    } catch (error) {
      console.error("Can't get module " + id, error.response.data);
      throw error.response.data;
    }
  }
}

export default new ModuleService();
