import { Button, InputText } from "app/components";
import style from "./UserAccountForm.module.scss";
import { useState } from "react";
import { userService } from "app/services";
import { toast } from "react-toastify";
import { useAuth } from "../../context";

export default function UserAccountForm() {
  const { user, setUser } = useAuth();
  const [data, setData] = useState({
    id: user?.id,
    email: user?.email,
    firstname: user?.firstname,
    lastname: user?.lastname,
  });

  const [error, setError] = useState();

  function handleOnChange(name, value) {
    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    setError();
    try {
      await userService.updateMe(data);
      setUser(userService.getStoredUser());
      toast.success("Vos informations ont bien été sauvegardées");
    } catch (error) {
      setError(error.errors);
    }
  }

  return (
    <div className={style.container}>
      <form onSubmit={handleOnSubmit}>
        <InputText
          disabled
          type="email"
          value={data.email}
          label="Email"
          name="email"
          required
          onChange={handleOnChange}
          errors={error}
        />
        <InputText value={data.firstname} label="Prénom" name="firstname" onChange={handleOnChange} errors={error} />
        <InputText value={data.lastname} label="Nom" name="lastname" onChange={handleOnChange} errors={error} />

        {error && <div className={style.error}>{error?.message}</div>}
        <div className={style.button}>
          <Button type="submit" fullwidth>
            Sauvegarder
          </Button>
        </div>
      </form>
    </div>
  );
}
