import Utils from "app/utils/Utils";
import style from "./ToggleButton.module.scss";

export default function ToggleButton({ text, checked, onChange }) {
  const id = Utils.random(6);

  return (
    <>
      <div className={style.container}>
        <input
          checked={checked}
          type="checkbox"
          className={style.switch}
          id={"sw-" + id}
          onChange={({ target }) => {
            onChange(target.checked);
          }}
        />
        <label className={style.switch} htmlFor={"sw-" + id} />
      </div>
    </>
  );
}
