import { Header, Container, Text, Footer, TeamMembersList, Loader, Icon } from "app/components";
import { CVList, TagList } from "./components";
import style from "./MemberScreen.module.scss";
import { Constants } from "app/utils";
import { memberService, specialityService } from "../../services";
import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { mdiCalendar } from "@mdi/js";

export default function MemberScreen() {
  const [data, setData] = useState();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const member = await memberService.getOne(params.id);
    const team = member.speciality ? await specialityService.getProfessionals(member.speciality.id, member.id) : [];
    setData({ member, team });
    setLoading(false);
  }

  const avatar = useMemo(() => {
    if (data?.member?.picture) {
      return Constants.routes.API_URL + data.member.picture.url;
    }
    return Constants.variables.DEFAULT_AVATAR;
  }, [data]);

  const avatarStyle = {
    backgroundImage: "url(" + avatar + ")",
  };

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else {
      return data?.member ? (
        <>
          <div className={style.memberContainer}>
            {data?.member?.speciality && (
              <Text linkTo={"/team/" + data.member.speciality.id} type="h3">
                <img src="/back.svg" height="60" width="60" alt="Back" />
              </Text>
            )}
            <div className={style.content}>
              <div className={style.sheet}>
                <div className={style.avatar} style={avatarStyle}></div>
                <div className={style.info}>
                  <div className={style.title}>
                    <Text type="h1">{data.member.fullname}</Text>
                    {data.member.appointement_url && (
                      <Text linkTo={data.member.appointement_url} targetBlank title="Prendre un rendez-vous">
                        <Icon path={mdiCalendar} size={1} color="#303030" />
                      </Text>
                    )}
                  </div>
                  <Text type="h3" color="blue">
                    {data.member.title}
                  </Text>
                  <div dangerouslySetInnerHTML={{ __html: data.member.description }} />
                </div>
              </div>
              <div className={style.infos}>
                <Text type="bold">Formations/ CV</Text>
                {data.member?.trainings && <CVList cv={data.member.trainings} />}
                {/* <TagList tags={data.member.tags} /> */}
              </div>
            </div>
          </div>
          {data.team.length > 0 && (
            <div className={style.teamContainer}>
              <Text type="h1" color="orange">
                Notre équipe
              </Text>
              <TeamMembersList members={data.team} />
            </div>
          )}
        </>
      ) : (
        <Text>Aucun membre trouvé</Text>
      );
    }
  }, [loading, data, avatar]);

  return (
    <>
      <Header />
      <Container>{content}</Container>
      <Footer />
    </>
  );
}
