import { Text } from "app/components";
import { useMemo } from "react";
import style from "./ModuleCourse.module.scss";
import { Link } from "react-router-dom";

export default function ModuleCourse({ course, position }) {
  const { title, description, type, finished, id } = course;

  const iconName = useMemo(() => {
    return `${type}${finished ? "_done" : ""}`;
  }, [type, finished]);

  const linkCls = useMemo(() => {
    return `${style.link} ${finished ? style.done : ""}`;
  }, [finished]);

  const linkTxt = useMemo(() => {
    switch (type) {
      case "video":
        return finished ? "Revoir la vidéo" : "Visionner la vidéo";
      case "article":
        return finished ? "Relire l'article" : "Lire l'article";
      default:
        return "";
    }
  }, [type, finished]);

  return (
    <div className={`${style.container} ${style[position]}`}>
      <div className={style.icon}>
        <img src={`/icons/${iconName}.svg`} alt={type} height={40} width={40} />
      </div>
      <div className={`${style.content} ${finished ? "" : style.todo}`}>
        <div className={style.title}>
          <Text type="h3">{title}</Text>
          <Text>{description}</Text>
        </div>
        <div>
          <Link className={linkCls} to={`/course/${id}`}>
            {linkTxt}
          </Link>
        </div>
      </div>
    </div>
  );
}
