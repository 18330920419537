import { Button, Container, Footer, Header, Text } from "../../components";
import { userService } from "app/services";
import style from "./UserScreen.module.scss";
import { UserAccountForm } from "app/forms";

export default function UserScreen() {
  async function handleOnLogout() {
    await userService.logout();
    window.localStorage.clear();
    window.location = "/";
  }

  return (
    <>
      <Header />
      <div className={style.container}>
        <Container>
          <Text type="h1" color="orange">
            Mon compte
          </Text>
          <UserAccountForm />
          <div className={style.forgottenPasswordContainer}>
            <Text linkTo="/mot-de-passe-oublie" type="small" targetBlank>
              Modifier mon mot de passe
            </Text>
          </div>

          <Button negative tiny onClick={handleOnLogout}>
            Se déconnecter
          </Button>
        </Container>
      </div>
      <Footer />
    </>
  );
}
