const BASE_URL = {
  production: "/api",
  development: "http://localhost:4200",
};

const API_URL = BASE_URL[process.env.NODE_ENV];

const variables = {
  WOON_USER: "WOON_USER",
  COOKIE_REFRESH_ID: "woon_refresh_token",
  COOKIE_ACCESS_ID: "woon_access_token",
  DEFAULT_PRO_AVATAR: "/avatar-big.jpg",
  DEFAULT_MODULE_AVATAR: "/avatar-big.jpg",
};

export const roles = {
  ROLE_CUSTOMER: 1,
  ROLE_ADMIN: 2,
};

export const routes = {
  // Routes
  API_URL,
  SIGNIN_URL: "/auth/signin/",
  FORGOTTEN_PASSWORD_URL: "/auth/forgottenPassword/",
  RESET_PASSWORD_URL: "/auth/resetPassword/",
  LOGOUT_URL: "/auth/logout/",
  REFRESH_URL: "/auth/refresh/",
  USER_ME: "/user/me",
  MODULES: "/modules",
  COURSES: "/courses",
  COURSE_NEXT: "/courses/next",
  SPECIALITIES: "/specialities",
  PROFESSIONALS: "/professionals",
  USERS: "/user",
  USERS_PAGINATED: "/user/paginated",
  USERS_ACTIVE_URL: "/user/active",
};

const constants = { variables, routes, roles };

export default constants;
