import { Button, InputText, Text } from "app/components";
import style from "./ResetPasswordForm.module.scss";
import { useCallback, useState } from "react";
import { userService } from "app/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ResetPasswordForm({ token }) {
  const [data, setData] = useState({
    pass1: undefined,
    pass2: undefined,
    token,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOnChange = useCallback(
    function (name, value) {
      setData({
        ...data,
        [name]: value,
      });
    },
    [setData, data]
  );

  async function handleOnSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setError();
    if (data.pass1 !== data.pass2) {
      setLoading(false);
      return setError({ message: "Les deux mots de passes ne se correspondent pas." });
    }
    try {
      await userService.resetPassword(data);
      toast.success("Votre mot de passe a bien été modifié. Vous pouvez vous dès à présent vous connecter.");
      navigate("/login");
    } catch (error) {
      if (error.errors?.length) {
        setError(error.errors[0]);
      } else {
        setError(error?.errors);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={style.container}>
      <form onSubmit={handleOnSubmit}>
        <InputText
          type="password"
          label="Mot de passe"
          name="pass1"
          required
          onChange={handleOnChange}
          autocomplete="password"
        />
        <InputText
          type="password"
          label="Confirmez le mot de passe"
          name="pass2"
          required
          onChange={handleOnChange}
          autocomplete="password"
        />

        {error && <div className={style.error}>{error.message}</div>}
        <div className={style.button}>
          <Button type="submit" fullwidth loading={loading}>
            Réinitialiser mon mot de passe
          </Button>
        </div>
      </form>
    </div>
  );
}
