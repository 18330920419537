import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import { Button, InputText } from "app/components";
import style from "./AddUserForm.module.scss";
import { userService } from "app/services";
import { roles } from "app/utils/Constants";
import { Utils } from "../../utils";

export default function AddUserForm({ user }) {
  const [data, setData] = useState({
    id: user?.id,
    email: user?.email,
    firstname: user?.firstname,
    lastname: user?.lastname,
    role: roles.ROLE_CUSTOMER,
  });

  const [error, setError] = useState();
  const [changePassword, setChangePassword] = useState(!user?.id || false);

  const navigate = useNavigate();

  function handleOnChange(name, value) {
    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    setError();
    try {
      if (data.id) {
        await userService.update(data);
      } else {
        await userService.insert(data);
      }
      toast.success("L'utilisateur a bien été sauvegardé");
      return navigate("/admin");
    } catch (error) {
      setError(error.errors);
    }
  }

  function handleRandomPassword() {
    setData({
      ...data,
      password: Utils.generatePassword(),
    });
  }

  return (
    <div className={style.container}>
      <form onSubmit={handleOnSubmit}>
        <InputText
          type="email"
          value={data.email}
          label="Email"
          name="email"
          required
          onChange={handleOnChange}
          errors={error}
        />
        <InputText value={data.firstname} label="Prénom" name="firstname" onChange={handleOnChange} errors={error} />
        <InputText value={data.lastname} label="Nom" name="lastname" onChange={handleOnChange} errors={error} />
        {changePassword ? (
          <>
            <InputText
              value={data.password}
              type="password"
              label="Nouveau mot de passe"
              name="password"
              required
              onChange={handleOnChange}
              errors={error}
            />
            <small className={style.randomPassword} onClick={handleRandomPassword}>
              Générer aléatoirement
            </small>
          </>
        ) : (
          <div className={style.changePasswordText} onClick={() => setChangePassword(true)}>
            Changer le mot de passe
          </div>
        )}
        {error && <div className={style.error}>{error?.message}</div>}
        <div className={style.button}>
          <Button type="submit" fullwidth>
            Sauvegarder
          </Button>
        </div>
      </form>
    </div>
  );
}
