import style from "./VideoContent.module.scss";
import { BlockRender } from "app/components";

export default function VideoContent({ content }) {
  return (
    <div className={style.container}>
      {content.map((block, index) => (
        <BlockRender key={index} block={block} />
      ))}
    </div>
  );
}
