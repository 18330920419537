import { ModuleChapter, ModuleContent } from "./components";
import { Accordion } from "app/components";
import style from "./ModuleChaptersList.module.scss";
import { useMemo } from "react";

export default function ModuleChaptersList({ chapters }) {
  const content = useMemo(() => {
    if (chapters?.length > 0) {
      const accordionContent = chapters.map((chapter, index) => {
        return {
          title: <ModuleChapter index={index + 1} title={chapter.title} />,
          content: chapter.courses ? (
            <ModuleContent courses={chapter.courses} />
          ) : (
            <div className={style.noContent}>Aucun cours pour le moment</div>
          ),
        };
      });
      return <Accordion items={accordionContent} forceActive={chapters?.length === 1} />;
    }
    return <div className={style.noContent}>Aucun chapitre pour le moment</div>;
  }, [chapters]);

  return (
    <>
      <div className={style.container}>{content}</div>
    </>
  );
}
