import { Api, Constants } from "app/utils";

class SpecialityService {
  async getAll() {
    try {
      const response = await Api.get(Constants.routes.SPECIALITIES);
      return response.data || [];
    } catch (error) {
      console.error("Can't get specilities", error.response.data);
      throw error.response.data;
    }
  }

  async getOne(id) {
    try {
      const response = await Api.get(`${Constants.routes.SPECIALITIES}/${id}`);
      return response.data || [];
    } catch (error) {
      console.error("Can't get specility " + id, error.response.data);
      throw error.response.data;
    }
  }

  async getProfessionals(id, exclude = []) {
    exclude = !Array.isArray(exclude) ? [exclude] : exclude;
    try {
      const response = await Api.get(`${Constants.routes.SPECIALITIES}/${id}`);
      const professionals = response.data.professionals;
      const filteredProfessionals = professionals.filter((professional) => !exclude.includes(professional.id));
      return filteredProfessionals || [];
    } catch (error) {
      console.error("Can't get professionals for specialidty " + id, error.response.data);
      throw error.response.data;
    }
  }
}

export default new SpecialityService();
