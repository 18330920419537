import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  AddUserScreen,
  AdminScreen,
  CourseScreen,
  ForgottenPasswordScreen,
  LoginScreen,
  MemberScreen,
  ModuleScreen,
  ModulesScreen,
  OnboardingScreen,
  TeamScreen,
  UserScreen,
} from "app/screens";
import { Constants } from "app/utils";
import ProtectedRoute from "./ProtectedRoute";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <ModulesScreen />
            </ProtectedRoute>
          }
        />
        <Route exact path="/login" element={<LoginScreen />} />
        <Route exact path="/mot-de-passe-oublie" element={<ForgottenPasswordScreen />} />
        <Route exact path="/mot-de-passe-oublie/confirmation/:token" element={<ForgottenPasswordScreen />} />
        <Route
          exact
          path="/onboarding"
          element={
            <ProtectedRoute>
              <OnboardingScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/me"
          element={
            <ProtectedRoute>
              <UserScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/modules"
          element={
            <ProtectedRoute>
              <ModulesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/module/:id"
          element={
            <ProtectedRoute>
              <ModuleScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/team/:id"
          element={
            <ProtectedRoute>
              <TeamScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/member/:id"
          element={
            <ProtectedRoute>
              <MemberScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/course/:id"
          element={
            <ProtectedRoute>
              <CourseScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin"
          element={
            <ProtectedRoute roles={[Constants.roles.ROLE_ADMIN]}>
              <AdminScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/add"
          element={
            <ProtectedRoute roles={[Constants.roles.ROLE_ADMIN]}>
              <AddUserScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/users/:id"
          element={
            <ProtectedRoute roles={[Constants.roles.ROLE_ADMIN]}>
              <AddUserScreen />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
