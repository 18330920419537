import style from "./OnboardingScreen.module.scss";
import { Header } from "app/components";
import { useState } from "react";
import { Nav, Step } from "./components";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    image: "/onboarding/Onboarding01.png",
    title: "Bienvenue !",
    html: "<p>Je suis <strong>Woon</strong>, votre compagnon de voyage.</p><p>Grâce à une équipe de professionnels de la santé et du développement, je vous propose de partir en voyage !<br>Notre destination ?<br>La découverte de soi !</p>",
  },
  {
    image: "/onboarding/Onboarding-02.svg",
    title: "Des outils",
    html: "<p>Tout au long de votre parcours, nos outils vous accompagnent pour vous permettre de vous re-connecter à vous-même !</p>",
  },
  {
    image: "/onboarding/Onboarding-03.svg",
    title: "Woonboard",
    html: "<p>Retrouvez à tout moment votre progression dans votre Woonboard. Emotions du jours, ressentis, outils enregistrés.</p><p>Prenez conscience du chemin parcouru !</p>",
  },
];

export default function OnboardingScreen() {
  const [step, setStep] = useState(0);
  const stepsCount = steps.length;
  const navigate = useNavigate();

  function handleOnClick(e) {
    if (step < stepsCount - 1) {
      setStep(step + 1);
    } else {
      navigate("/");
    }
  }

  return (
    <>
      <Header />
      <div className={style.container}>
        <div className={style.intro}>
          <Step step={steps[step]} handleClick={handleOnClick} />
          <Nav current={step} stepsCount={stepsCount} />
        </div>
      </div>
    </>
  );
}
