import React, { useEffect, useState } from "react";
import { isEmpty } from "app/utils/Utils";
import Api from "app/utils/Api";
import { Loader } from "app/components";
import style from "./Pagination.module.scss";

const Pagination = ({ url, initialData, search, filters, init = {}, onData, head, children }) => {
  const [state, setState] = useState({
    query: {
      ...init,
      pageIndex: 0,
      take: 25,
    },
    data: {
      total: 0,
    },
  });

  useEffect(() => {
    load();
  }, [url, state.query, initialData]);

  useEffect(() => {
    if (!isEmpty(state.data) && state.data.items) {
      onData(state.data.items);
    }
  }, [state.data]);

  function processSortAndPagination(data) {
    data.sort((a, b) => {
      if (state.query.sortOrder === "asc") {
        if (typeof a[state.query.sortBy] === "string") {
          return b[state.query.sortBy].localeCompare(a[state.query.sortBy]);
        }
        return a[state.query.sortBy] > b[state.query.sortBy] ? -1 : 1;
      } else {
        if (typeof a[state.query.sortBy] === "string") {
          return a[state.query.sortBy].localeCompare(b[state.query.sortBy]);
        }
        return a[state.query.sortBy] < b[state.query.sortBy] ? -1 : 1;
      }
    });

    if (state.query.search && search.fields) {
      data = data.filter((obj) => {
        for (let i = 0; i < search.fields.length; i++) {
          if (obj[search.fields[i]]?.toLowerCase().includes(state.query.search?.toLowerCase())) {
            return data;
          }
        }
      });
    }

    setState({
      ...state,
      data: {
        pageIndex: state.query.pageIndex,
        total: data.length,
        totalPages: Math.ceil(data.length / state.query.take),
        items: [...data].slice(
          state.query.pageIndex * state.query.take,
          state.query.pageIndex * state.query.take + state.query.take
        ),
      },
    });
  }

  async function load() {
    if (initialData) {
      processSortAndPagination(initialData);
    } else if (url) {
      let res = await Api.get(url, { params: state.query });
      if (res.data) {
        setState({ ...state, data: res.data });
      }
    }
  }

  function pagination(currentPage, pageCount) {
    let delta = 2,
      left = currentPage - delta,
      right = currentPage + delta + 1,
      result = [];

    result = Array.from({ length: pageCount }, (v, k) => k + 1).filter((i) => i && i >= left && i < right);

    if (result.length > 1) {
      if (result[0] > 1) {
        if (result[0] > 2) {
          result.unshift("...");
        }
        result.unshift(1);
      }

      if (result[result.length - 1] < pageCount) {
        if (result[result.length - 1] !== pageCount - 1) {
          result.push("...");
        }
        result.push(pageCount);
      }
    }

    return result.length > 1 ? result : [];
  }

  const ThSort = ({ name, children }) => {
    function isSorted(name) {
      if (state.query.sortBy === name) {
        if (state.query.sortOrder === "asc") {
          return "sort-down";
        }
        return "sort-up";
      }
      return "sort";
    }

    return (
      <th
        className={style.ptr}
        onClick={() => {
          setState({
            ...state,
            query: {
              ...state.query,
              sortBy: name,
              sortOrder: state.query.sortOrder === "asc" ? "desc" : "asc",
            },
          });
        }}
      >
        <div className={style.sortableContainer}>
          <div>{children}</div>
          {isSorted(name) === "sort" ? (
            <div className={style.sortable}>
              <i className={`${style.icon} ${style["sort-down"]}`} />
              <i className={`${style.icon} ${style["sort-up"]}`} />
            </div>
          ) : (
            <i className={`${style.icon} ${style[isSorted(name)]}`} />
          )}
        </div>
      </th>
    );
  };

  function handleChangeFilter({ target }) {
    setState({
      ...state,
      query: {
        ...state.query,
        [target.name]: target.value,
      },
    });
  }

  return (
    <React.Fragment>
      {search && (
        <input
          onChange={({ target }) =>
            setState({
              ...state,
              query: {
                ...state.query,
                search: target.value,
                pageIndex: 0,
              },
            })
          }
          type="text"
          className="form-control col-4"
          placeholder={search.placeholder || "Rechercher ..."}
        />
      )}
      {filters && Array.isArray(filters) && filters.length > 0 && (
        <div className="form card p-3 pb-4">
          <div className="row">
            {filters && filters.length !== 0 ? (
              filters.map((s, i1) => {
                return (
                  <div key={"s" + i1} className="col-2">
                    <label htmlFor="">{s.label}</label>
                    <select
                      value={state.query[s.parameter]}
                      name={s.parameter}
                      className="form-control w-100"
                      onChange={handleChangeFilter}
                    >
                      {s.data.map((d, i2) => {
                        return (
                          <React.Fragment key={"s" + i1 + "d" + i2}>
                            {i2 == 0 && <option value={-1}>Tous</option>}
                            <option value={d.id}>{d.label}</option>
                          </React.Fragment>
                        );
                      })}
                    </select>
                  </div>
                );
              })
            ) : (
              <Loader align="text-center m-auto" />
            )}
          </div>
        </div>
      )}
      <div className={style.pager}>
        <div>
          <ul className={style.pagination}>
            {!isEmpty(state.data) &&
              pagination(state.data.pageIndex, state.data.totalPages).map((x, i) => {
                if (x === "...") {
                  return (
                    <li key={"pagination-" + i} className="page-item inactive">
                      ...
                    </li>
                  );
                } else {
                  return (
                    <li key={"pagination-" + i} className={`${state.data.pageIndex + 1 === x ? style.active : ""}`}>
                      <button
                        className="page-link"
                        onClick={() =>
                          setState({
                            ...state,
                            query: {
                              ...state.query,
                              pageIndex: x - 1,
                            },
                          })
                        }
                      >
                        {x}
                      </button>
                    </li>
                  );
                }
              })}
          </ul>
          <small>Total : {state.data.total}</small>
        </div>
        <div>
          <small style={{ width: 125 }}>Résultats par page : </small>

          <select
            defaultValue={25}
            className={style.nbResults}
            style={{ width: 70 }}
            onChange={({ target }) =>
              setState({
                ...state,
                query: {
                  ...state.query,
                  pageIndex: 0,
                  take: target.value,
                },
              })
            }
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      {state.data.total !== 0 ? (
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead>
              <tr>
                {Object.entries(head).map((obj) => {
                  if (obj[1] instanceof Object && obj[1].sort === false) {
                    return <th key={"th-" + obj[0]}>{obj[1].label}</th>;
                  } else {
                    return (
                      <ThSort key={"th-sort-" + obj[0]} name={obj[0]}>
                        {obj[1]}
                      </ThSort>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      ) : (
        <div>Aucun résultat</div>
      )}
    </React.Fragment>
  );
};

export default Pagination;
