import style from "./Modal.module.scss";
import { Icon } from "app/components";
import { mdiClose } from "@mdi/js";

export default function Modal({ children, opened, onClose, closable = false }) {
  // TODO : use createPortal ?
  return (
    <div className={`${style.modalContainer} ${opened ? style.opened : ""}`} onClick={onClose}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        {closable ? (
          <div className={style.icon}>
            <Icon path={mdiClose} onClick={onClose} size={1} color="grey" />
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </div>
  );
}
