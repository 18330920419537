import { Text, Button } from "app/components";
import { ArticleContent } from "./components";
import style from "./ArticleCourse.module.scss";

export default function ArticleCourse({ course, onClick }) {
  const buttonTitle = "J'ai lu l'article";
  return (
    <div className={style.container}>
      <div className={style.title}>
        <Text type="h1">{course.title}</Text>
      </div>

      <div className={style.content}>
        <ArticleContent content={course.content} />
      </div>

      {!course.finished && (
        <div className={style.finish}>
          <Button onClick={onClick}>{buttonTitle}</Button>
        </div>
      )}
    </div>
  );
}
