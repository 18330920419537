import style from "./Menu.module.scss";
import { useAuth } from "app/context";
import { Constants } from "app/utils";
import { Link } from "react-router-dom";

export default function Menu() {
  const { user } = useAuth();
  const pathname = window.location.pathname;
  return (
    <nav className={style.menu}>
      <ul>
        <li className={pathname === "/" ? style.current : ""}>
          <Link to="/">Les modules</Link>
        </li>
        {user.roles_id === Constants.roles.ROLE_ADMIN && (
          <li className={pathname === "/admin" ? style.current : ""}>
            <Link to="/admin">Administration</Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
