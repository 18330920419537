import { Container, Footer, Header, ModuleList, Text, Loader } from "app/components";
import { TeamList } from "./components";
import style from "./ModulesScreen.module.scss";
import { useEffect, useState, useMemo } from "react";
import { moduleService, specialityService } from "app/services";

export default function ModulesScreen() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const modules = await moduleService.getAll();
      const teams = await specialityService.getAll();
      setData({ modules, teams });
      setLoading(false);
    } catch (error) {}
  }

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else {
      let moduleContent = data?.modules?.length > 0 ? <ModuleList modules={data.modules} /> : <Text>Aucun module</Text>;
      let teamContent = data?.teams?.length > 0 ? <TeamList teams={data.teams} /> : <Text>Aucun membre</Text>;
      return (
        <Container>
          <Text type="h1" color="orange">
            Les modules
          </Text>
          {moduleContent}
          <Text type="h1" color="orange">
            Notre équipe
          </Text>
          {teamContent}
        </Container>
      );
    }
  }, [loading, data]);

  return (
    <>
      <Header />
      <div className={style.container}>{content}</div>
      <Footer />
    </>
  );
}
