import { useEffect, useState } from "react";
import style from "./AddUserScreen.module.scss";
import { BackButton, Container, Footer, Header, Loader, Text } from "app/components";
import { AddUserForm } from "app/forms";
import { useParams } from "react-router-dom";
import { userService } from "app/services";

export default function AddUserScreen() {
  const [user, setUser] = useState();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    if (params.id) {
      const _user = await userService.getOne(params.id);
      setUser(_user);
    }
    setLoading(false);
  }

  function renderBody() {
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={style.formContainer}>
        <div className={style.header}>
          <BackButton />
          <Text type="h2">{user?.id ? "Edition d'un utilisateur" : "Ajout d'un utilisateur"}</Text>
        </div>
        <AddUserForm user={user} />
      </div>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <div className={style.container}>{renderBody()}</div>
      </Container>
      <Footer />
    </>
  );
}
