import { Api, Constants } from "app/utils";

class CourseService {
  async getOne(id) {
    const response = await Api.get(`${Constants.routes.COURSES}/${id}`);
    return response.data || undefined;
  }

  async getNext(id) {
    const response = await Api.get(`${Constants.routes.COURSE_NEXT}/${id}`);
    return response.data || undefined;
  }
}

export default new CourseService();
