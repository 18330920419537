import { Text } from "app/components";
import { Constants } from "app/utils";
import style from "./TeamMember.module.scss";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default function TeamMember({ member }) {
  const avatar = useMemo(() => {
    if (member?.picture) {
      return Constants.routes.API_URL + member.picture.url;
    }
    return Constants.variables.DEFAULT_AVATAR;
  }, [member]);

  const avatarStyle = {
    backgroundImage: "url(" + avatar + ")",
  };

  return (
    <div className={style.member}>
      <Link to={"/member/" + member.id}>
        <div className={style.avatar} style={avatarStyle}></div>
        <div className={style.descr}>
          <div>
            <Text color="dark-blue" type="bold">
              {member.fullname}
            </Text>
          </div>
          <div>
            <Text color="dark-blue">{member.title}</Text>
          </div>
        </div>
      </Link>
    </div>
  );
}
