import style from "./TeamCat.module.scss";
import { Text } from "app/components";
import { Link } from "react-router-dom";

export default function TeamCat({ cat }) {
  return (
    <div className={style.item}>
      <Link to={`/team/${cat.id}`}>
        <Text type="bold">{cat.name}</Text>
      </Link>
    </div>
  );
}
