import style from "./Button.module.scss";
import { Loader } from "app/components";

export default function Button({
  onClick,
  loading = false,
  type = "button",
  fullwidth = false,
  negative = false,
  large = false,
  tiny = false,
  children,
}) {
  return (
    <button
      disabled={loading}
      type={type}
      className={`${style.button} ${fullwidth ? style.fullwidth : ""} ${negative ? style.negative : ""} ${
        large ? style.large : ""
      } ${tiny ? style.tiny : ""}`}
      onClick={onClick}
    >
      {loading ? <Loader color="#FFF" scale="small" /> : children}
    </button>
  );
}
