import style from "./InputText.module.scss";
import { useState } from "react";
import { Icon } from "app/components";
import { mdiEye, mdiEyeOff } from "@mdi/js";

export default function InputText({
  type = "text",
  name,
  label,
  required = false,
  onChange,
  errors,
  showtext = false,
  ...props
}) {
  const [showText, setShowText] = useState(showtext || type !== "password");

  function renderErrors() {
    if (errors && errors.length) {
      let error = errors.find((error) => error.field === name);
      if (error) {
        return <div className={style.fieldError}>{error.message}</div>;
      }
    }
  }

  return (
    <div className={style.container}>
      {label && <label>{label}</label>}
      <div className={style.wrapper}>
        <input
          type={type === "password" ? (showText ? "text" : "password") : type}
          required={required}
          className={`${label && style.withLabel}`}
          onChange={({ target }) => onChange(name, target.value)}
          defaultValue=""
          {...props}
        />
        {type === "password" && (
          <div className={style.hint} onClick={() => setShowText(!showText)}>
            <Icon path={showText ? mdiEyeOff : mdiEye} size={1} color="grey" />
          </div>
        )}
        {renderErrors()}
      </div>
    </div>
  );
}
