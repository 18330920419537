import setObjectValue from "set-value";
import { parseISO, format } from "date-fns";

export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export function parseDate(dateStr, formatStr) {
  let date = parseISO(dateStr);
  return format(date, formatStr || "dd/MM/yyyy - HH:mm:ss");
}

function generatePassword(length = 8) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  if (/[A-Z]/.test(result) && /[a-z]/.test(result) && /[0-9]/.test(result)) {
    return result;
  } else {
    return generatePassword(length);
  }
}

const exportedObject = {
  setObjectValue,
  generatePassword,
  random: (size) => {
    return Array(size)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map((x) => {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
  },
};

export default exportedObject;
