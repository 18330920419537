import { Button, InputText, Text } from "app/components";
import style from "./LoginForm.module.scss";
import { useCallback, useState } from "react";
import { userService } from "app/services";

export default function LoginForm() {
  const [data, setData] = useState({
    email: undefined,
    password: undefined,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleOnChange = useCallback(
    function (name, value) {
      setData({
        ...data,
        [name]: value,
      });
    },
    [setData, data]
  );

  async function handleOnSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setError();
    try {
      await userService.login(data.email, data.password);
      window.location = "/";
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={style.container}>
      <form onSubmit={handleOnSubmit}>
        <InputText label="Email" name="email" required onChange={handleOnChange} autocomplete="email" />
        <InputText
          type="password"
          label="Mot de passe"
          name="password"
          required
          onChange={handleOnChange}
          autocomplete="password"
        />
        <div className={style.forgottenPasswordContainer}>
          <Text linkTo="/mot-de-passe-oublie" type="small">
            Mot de passe oublié ?
          </Text>
        </div>
        {error && <div className={style.error}>{error.message}</div>}
        <div className={style.button}>
          <Button type="submit" fullwidth loading={loading}>
            Se connecter
          </Button>
        </div>
        <div className={style.grayBlock}>
          <Text type="small">En m’inscrivant, j’accepte les </Text>
          <Text linkTo="https://woon.care/mentions-legales/" color="orange" type="small" targetBlank>
            conditions générales d’utilisation
          </Text>
          <Text type="small"> et la </Text>
          <Text linkTo="https://woon.care/politique-de-cookies-ue/" color="orange" type="small" targetBlank>
            politique de confidentialité
          </Text>
          <Text type="small">. Je consens au </Text>
          <Text linkTo="https://woon.care/politique-de-cookies-ue/" color="orange" type="small" targetBlank>
            traitement de mes données personnelles
          </Text>
          <Text type="small"> par Woon.</Text>
        </div>
      </form>
    </div>
  );
}
