import style from "./TeamMembersList.module.scss";
import { TeamMember } from "./components";

export default function TeamMembersList({ members }) {
  return (
    <>
      <ul className={style.list}>
        {members &&
          members.map((member) => (
            <li key={member.id}>
              <TeamMember member={member} />
            </li>
          ))}
      </ul>
    </>
  );
}
