import React from "react";
import style from "./Loader.module.scss";

const Loader = ({ color = "orange" }) => {
  const innerStyle = {
    "--color": color,
  };

  return (
    <div style={{ position: "relative" }}>
      &nbsp;
      <div style={innerStyle} className={`${style.spinner}`} />
    </div>
  );
};

export default Loader;
