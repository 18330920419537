import style from "./CVList.module.scss";
export default function CVList({ cv }) {
  return (
    <ul className={style.list}>
      {cv.map((line) => (
        <li key={line.id}>{line.text}</li>
      ))}
    </ul>
  );
}
