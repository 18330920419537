import style from "./BlockRender.module.scss";
import { Text } from "app/components";
import { Constants } from "app/utils";
import { useMemo } from "react";

export default function BlockRender({ block }) {
  const renderedBlock = useMemo(() => {
    switch (block.type) {
      case "h1":
        return (
          <h1>
            <Text type="h1">{block.text}</Text>
          </h1>
        );
      case "h2":
        return (
          <h2>
            <Text type="h2">{block.text}</Text>
          </h2>
        );
      case "h3":
        return (
          <h3>
            <Text type="h3">{block.text}</Text>
          </h3>
        );
      case "p":
        return <div dangerouslySetInnerHTML={{ __html: block.text.replace(/href/g, "target='_blank' href") }} />;
      case "image":
        return (
          <p>
            <img
              className={style.image}
              src={`${Constants.routes.API_URL}${block.image.url}`}
              alt={block.image.alternativeText}
            />
          </p>
        );
      case "video":
        return <div dangerouslySetInnerHTML={{ __html: block.video }} />;
      case "image-text":
        return (
          <div className={style.cols}>
            <div>
              <img
                className={style.image}
                src={`${Constants.routes.API_URL}${block.image.url}`}
                alt={block.image.alternativeText}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: block.text.replace(/href/g, "target='_blank' href") }} />
          </div>
        );
      case "text-image":
        return (
          <div className={style.cols}>
            <div dangerouslySetInnerHTML={{ __html: block.text.replace(/href/g, "target='_blank' href") }} />
            <div>
              <img
                className={style.image}
                src={`${Constants.routes.API_URL}${block.image.url}`}
                alt={block.image.alternativeText}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [block]);

  return <>{renderedBlock}</>;
}
