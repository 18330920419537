import { BackButton, Container, Text } from "app/components";

import style from "./ModuleHeader.module.scss";

export default function ModulesHeader({ title }) {
  return (
    <>
      <div className={style.container}>
        <Container>
          <div className={style.header}>
            <BackButton />
            <Text type="h2">{title}</Text>
          </div>
        </Container>
      </div>
    </>
  );
}
