import { createContext, useState, useContext } from "react";
import { userService } from "app/services";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(userService.getStoredUser());

  return { user, setUser };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
