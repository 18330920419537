import { Header, Container, Footer, Modal, Text, Button, BackButton, Loader } from "app/components";
import { ArticleCourse, VideoCourse } from "./components";
import { useMemo, useCallback, useState, useEffect } from "react";
import style from "./CourseScreen.module.scss";
import { useNavigate, useParams, redirect } from "react-router-dom";
import { courseService } from "app/services";

export default function CourseScreen() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      load();
    }
  }, [params]);

  async function load() {
    const course = await courseService.getOne(params.id);
    setData({ course });
    setLoading(false);
  }

  const [modalOpened, setModalOpened] = useState(false);

  const handleOnClick = useCallback(() => {
    setModalOpened(!modalOpened);
  }, [modalOpened, setModalOpened]);

  const handleOnClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleFinishActivity = async () => {
    // TODO: finish activity and then close modal
    setModalLoading(true);
    const id = await courseService.getNext(data.course.id);
    setModalOpened(false);
    setModalLoading(false);
    if (!id) {
      return navigate("/");
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    return navigate("/course/" + id);
  };

  const activityComponent = useMemo(() => {
    switch (data?.course?.type) {
      case "video":
        return <VideoCourse course={data.course} onClick={handleOnClick} />;
      case "article":
        return <ArticleCourse course={data.course} onClick={handleOnClick} />;
      default:
        return <Text>Type d'activité inconnue.</Text>;
    }
  }, [data?.course, handleOnClick]);

  const content = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else {
      return data ? (
        <>
          {activityComponent}

          <Modal opened={modalOpened} onClose={handleOnClose} closable>
            <div className={style.modalTitle}>
              <Text type="h2">Avez-vous terminé cette activité ?</Text>
            </div>
            <div className={style.buttonBar}>
              <Button negative large onClick={handleOnClose}>
                Non
              </Button>
              <Button large onClick={handleFinishActivity} loading={modalLoading}>
                Oui
              </Button>
            </div>
          </Modal>
        </>
      ) : (
        <Text>Aucun contenu de cours trouvé</Text>
      );
    }
  }, [loading, data, activityComponent, modalOpened, handleOnClose, handleFinishActivity, modalLoading]);

  return (
    <>
      <Header />
      <div className={style.container}>
        <Container>
          <BackButton />
          {content}
        </Container>
      </div>
      <Footer />
    </>
  );
}
