import { Text } from "app/components";
import style from "./ModuleChapter.module.scss";

export default function ModuleChapter({ title, index }) {
  return (
    <>
      <div className={`${style.container} ${index === 1 ? style.first : ""}`}>
        <Text color="orange">
          <span className={style.title}>Chapitre {index}</span>
        </Text>
        <Text type="h3">{title}</Text>
      </div>
    </>
  );
}
