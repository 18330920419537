import style from "./Nav.module.scss";

export default function Nav({ current, stepsCount }) {
  let nav = [];
  for (let i = 0; i < stepsCount; i++) {
    nav.push(<li key={i} className={`${current === i && style.current} ${style.navItem}`}></li>);
  }

  return (
    <nav className={style.nav}>
      <ul>{nav}</ul>
    </nav>
  );
}
