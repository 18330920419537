import { Button, InputText, Text } from "app/components";
import style from "./ForgottenPasswordForm.module.scss";
import { useCallback, useState } from "react";
import { userService } from "app/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ForgottenPasswordForm() {
  const [data, setData] = useState({
    email: undefined,
  });
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleOnChange = useCallback(
    function (name, value) {
      setData({
        ...data,
        [name]: value,
      });
    },
    [setData, data]
  );

  async function handleOnSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setError();
    try {
      await userService.forgottenPassword(data.email);
      toast.success(
        "Un email contenant un lien de réinitialisation a été envoyé à votre adresse email. N'oubliez pas de vérifier également dans vos SPAM."
      );
      navigate("/login");
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={style.container}>
      <form onSubmit={handleOnSubmit}>
        <InputText label="Email" name="email" required onChange={handleOnChange} autocomplete="email" />

        <div className={style.forgottenPasswordContainer}>
          <Text linkTo="/login" type="small">
            Retour au formulaire de connexion
          </Text>
        </div>
        {error && <div className={style.error}>{error.message}</div>}
        <div className={style.button}>
          <Button type="submit" fullwidth loading={loading}>
            Réinitialiser mon mot de passe
          </Button>
        </div>
      </form>
    </div>
  );
}
